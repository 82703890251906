import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        redirect: '/lista-piese'
      },
      {
        path: '/lista-piese',
        name: 'lista-piese',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "about" */ '../views/ListaPiese.vue')
      },
      {
        path: '/cereri-piese',
        name: 'cereri-piese',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "about" */ '../views/CereriPiese.vue')
      },
      {
        path: '/vinde-piesa',
        name: 'vinde-piesa',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "about" */ '../views/VindePiesa.vue')
      },
      {
        path: '/cerere-noua',
        name: 'cerere-noua',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "about" */ '../views/CerereNoua.vue')
      },
      {
        path: "/profil",
        name: 'profil',
        meta: { auth: true },
        component: () => import(/* webpackChunkName: "about" */ '../views/Profil.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async ({ params, meta, name: route }, from, next) => {
  if (route === 'login') {
    return next();
  }
  meta?.auth && !store.state.auth.user?.name
    ? await store.dispatch('get_user', next)
    : next();
});

export default router
