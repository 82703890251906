import { createStore } from 'vuex';
import * as auth from './modules/auth'
import api from "@/store/api"
import axios from "axios"
import router from "@/router"

export default createStore({
  state: {
    generating: false,
    data: {
      languageModel: 'openai',
      appType: 'webapp',
      dataSource: 'pdf/text',
      urlName: ''
    }
  },
  getters: {},
  mutations: {
    SET_STATE (state, {key, value}) {
      state[key] = value
    },
    SET_DATA (state, {key, value}) {
      state.data[key] = value
    }
  },
  actions: {
    async generate ({state, commit}) {
      try {
        commit('SET_STATE', {key: 'generating', value: true})
        const { data } = await axios.post(api.generate, state.data)
        await router.push('/configurator/success')
      } catch (e) {

      }
    },
  },
  modules: {
    auth
  }
});
